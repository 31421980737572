@import 'partials/pipeline';

$highlight: #3498db;

$parallax-intro-url:    url('../img/back.jpg') 0 0 no-repeat;
$parallax-newsletter-url: url('../img/back.jpg') 0 0 no-repeat;
$parallax-quote-url:    url('../img/back.jpg') 0 0 no-repeat;

/* ====================
2. PARTIAL SCSS FILES
==================== */

@import 'partials/partials';

/* ====================
14. USER CUSTOMISATION
==================== */
@font-face {
  font-family:HelveticaNeueCyr-Thin;
  src:url(../fonts/HelveticaNeueCyr-Thin.otf) format('opentype');
}

a.button,
button,
input[type='submit'] {
  background: $highlight;
  &:hover,
  &:focus {
    background-color: lighten($highlight, 5%);
  }
}

footer.footer p.copyright {
  width: 100%;
}

section.intro h3 {
  font-family: 'HelveticaNeueCyr-Thin';

  &.header_big {
    font-size: 56px;
    text-transform: none;
    @include media($small-screen-down) {
      font-size: 28px;
    }
    @include media($medium-screen-down) {
      font-size: 28px;
    }
    @include media($large-screen-down) {
      font-size: 28px;
    }
  }
  &.header_normal {
    font-size: 32px;
    text-transform: none;
    @include media($small-screen-down) {
      font-size: 24px;
    }
    @include media($medium-screen-down) {
      font-size: 22px;
    }
    @include media($large-screen-down) {
      font-size: 22px;
    }
  }
}

.container_content {
  padding-top: 7em;
}

//section.intro .image-bg {
section.intro .image-bg {
  @include media($small-screen-down) {
    background-position: center center;
    //color: #FF0000;
  }
}

.download-btns-desktop {
  text-align: center;
  @include media($small-screen-down) {
    display: none;
  }
  @include media($medium-screen-down) {
    display: none;
  }
  @include media($large-screen-down) {
    display: none;
  }
}
.download-btns-desktop__img {
  width: 250px;
  display: inline-block;
}
.download-btns-desktop__img {
  margin-right: 25px;
  
}

.download-btns-mobile {
  background: darken(white, 5%);
  @include media($small-screen-up) {
    display: none;
  }
  @include media($medium-screen-up) {
    display: none;
  }
  @include media($large-screen-down) {
    display: block;
    text-align: center;
  }
}

.download-btns-mobile__img {
  width: 175px;
  display: block;
  margin: 0 auto 5px;
  @include media($large-screen-down) {
    width: 280px;
    margin-bottom: 15px;
  }
}

section.intro a.jump-down {
  @include media($medium-screen-down) {
    bottom: 1em;
  }
  @include media($large-screen-down) {
    bottom: 1em;
  }
}
