body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: modular-scale(1);
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}

p {
  margin: 0 0 $small-spacing;
}

a {
  color: $action-color;
  text-decoration: none;
  transition: color $base-duration $base-timing;

  &:active,
  &:focus,
  &:hover {
    color: shade($action-color, 25%);
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}

img {
  margin: 0;
  max-width: 100%;
}

blockquote {
  border-left: 2px solid $base-border-color;
  color: lighten($base-font-color, 15);
  margin: $base-line-height 0;
  padding-left: $base-line-height / 2;
  line-height: 1.5em;
}

cite {
  color: lighten($base-font-color, 25);
  font-style: italic;

  &:before {
    content: '\2014 \00A0';
  }
}