
header.navigation {

  &.headroom--unpinned {
    transform: translateY(-100%);
  }
  &.headroom--pinned {
    transform: translateY(0%);
  }

  // VARIABLES

  $navigation-padding: 1em;
  $navigation-background: darken(white, 5%);
  $navigation-color: darken(white, 30%);
  $navigation-color-hover: $highlight;
  $navigation-height: 6em;
  $horizontal-bar-mode: $large-screen;
  $navigation-submenu-padding: 1em;
  $navigation-submenu-width: 12em;

  &.headroom {
    position: fixed;
    background-color: white;
    border-bottom: 1px solid darken(white, 10);
    height: $navigation-height;
    width: 100%;
    z-index: 999;

    transition: transform 200ms linear;

    .navigation-wrapper {
      @include clearfix;
      @include outer-container;
      position: relative;
      z-index: 9999;
    }

    .logo {
      float: left;
      max-height: $navigation-height;
      padding-left: $navigation-padding;

      img {
        max-height: $navigation-height;
        padding: 1.7em 0;
      }
    }

    // MOBILE VIEW

    .navigation-menu-button {
      color: $navigation-color;
      display: block;
      float: right;
      font-family: $sans-serif;
      font-weight: 700;
      line-height: $navigation-height;
      margin: 0;
      padding-right: 1em;
      text-transform: uppercase;

      @include media ($horizontal-bar-mode) {
        display: none;
      }

      &:hover {
        color: $navigation-color-hover;
      }
    }

    // NAVIGATION MENU

    .nav {
      z-index: 9999999;
      float: none;

      @include media ($horizontal-bar-mode) {
        float: right;
      }
    }

    ul#navigation-menu {
      -webkit-transform-style: preserve-3d;
      // stop webkit flicker
      clear: both;
      display: none;
      margin: 0 auto;
      overflow: visible;
      padding: 0;
      width: 100%;
      z-index: 9999;

      @include media ($horizontal-bar-mode) {
        display: inline;
        margin: 0;
        padding: 0;
      }
    }

    // NAVIGATION LINKS

    ul li.nav-link {

      @include media($large-screen-down) {
        padding-left: 0;
        text-align: center;
        border-bottom: solid 1px darken($navigation-background, 5%);
      }

      background: $navigation-background;
      display: block;
      line-height: $navigation-height;
      overflow: hidden;
      padding-left: 2em;
      text-align: right;
      width: 100%;
      z-index: 9999;

      @include media ($horizontal-bar-mode) {
        background: transparent;
        display: inline;
        line-height: $navigation-height;
        text-decoration: none;
        width: auto;
      }

      &.active a {
        color: $highlight;
      }

      a {
        display: inline-block;
        font-weight: 400;
        color: $navigation-color;

        @include media ($horizontal-bar-mode) {
          padding-right: 1em;
        }

        &:hover {
          color: $navigation-color-hover;
        }

        @include media($large-screen-down) {
          display: block;
        }
      }
    }
  }

  // Sub menus

  li.more.nav-link {
    padding-right: 0;

    @include media($large-screen) {
      padding-right: $navigation-submenu-padding;
    }

    > ul > li:first-child a {
      padding-top: 1em;
    }

    a {
      margin-right: $navigation-submenu-padding;

      @include media($large-screen-down) {
        margin-right: 0;
        padding: 0;
        text-align: center;
      }
    }

    > a {
      padding-right: 0.6em;
    }

    > a:after {
      @include position(absolute, auto -.4em auto auto);
      content: '\25BE';
      color: $navigation-color;
    }
  }

  li.more {
    overflow: visible;
    padding-right: 0;

    a {
      padding-right: .8em;
    }

    > a {
      padding-right: 1.6em;
      position: relative;

      @include media($large-screen) {
        margin-right: $navigation-submenu-padding;
      }

      &:after {
        content: '›';
        font-size: 1.2em;
        position: absolute;
        right: $navigation-submenu-padding / 2;
      }
    }

    &:hover > .submenu {
      display: block;
    }

    @include media($horizontal-bar-mode) {
      padding-right: .8em;
      position: relative;
    }
  }

  ul.submenu {
    display: none;
    padding-left: 0;

    @include media($horizontal-bar-mode) {
      position: absolute;
      top: 2.5em;
      left: 0;
    }

    .submenu {
      @include media($horizontal-bar-mode) {
        left: $navigation-submenu-width - .2em;
        top: 0;
      }
    }

    li {
      display: block;
      padding-right: 0;

      @include media($large-screen-down) {
        padding-left: 0;
        text-align: center;
        border-bottom: solid 1px darken($navigation-background, 8%);
      }

      @include media($horizontal-bar-mode) {
        line-height: 3.5em;

        &:first-child > a {
          border-top-left-radius: $base-border-radius;
          border-top-right-radius: $base-border-radius;
        }

        &:last-child > a {
          border-bottom-left-radius: $base-border-radius;
          border-bottom-right-radius: $base-border-radius;
          padding-bottom: 1.2em;
        }
      }

      a {
        background-color: darken($navigation-background, 3);
        display: inline-block;
        text-align: right;
        width: 100%;

        @include media($horizontal-bar-mode) {
          padding: 0;
          text-align: center;
        }

        @include media($horizontal-bar-mode) {
          background-color: $navigation-background;
          padding-left: $navigation-submenu-padding;
          text-align: left;
          width: $navigation-submenu-width;
        }
      }
    }
  }
  .language {
    display: inline-block;
    float: right;
    margin-left: 1.5em;
    position: relative;
    width: 78px;
    height: 96px;
    .lang_decor{
      height: 96px;
      width: 78px;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
    }
    .lang_now{
      line-height: 6em;
      color: #b3b3b3;
      span{
        padding: 0 10px;
      }
      img{
        margin-bottom: -5px;
        margin-right: 5px;
        border-radius: 50%;
        border: 1px solid #bebebe;
      }
    }
    .lang_list{
      position: relative;
      top: -2.2em;
      background: #ffffff;
      padding: 10px 0;
      border-radius: 7px;
      opacity: 0;
      transition: opacity 0.3s;
      a{
        cursor: pointer;
        color: #b3b3b3;
        padding: 10px;
        img{
          margin-bottom: -5px;
          margin-right: 5px;
          border-radius: 50%;
          border: 1px solid #bebebe;
        }
        &:hover{
          color: #3498db;
        }

      }
    }
    &:hover{
      cursor: default;
      .lang_decor{
        height: auto;
      }
      .lang_list{
        opacity: 1;
      }
    }
  }
}
@media(max-width: 480px){
  header.navigation .language{
    margin-left: 0;
  }
  section.intro .image-bg{
    background-attachment: inherit !important; /* специально для яблочек */
  }
}