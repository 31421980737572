// Breakpoints
$small-screen: em(480);
$medium-screen: em(600);
$large-screen: em(860);

$small-screen-up: new-breakpoint(min-width $small-screen 12);
$medium-screen-up: new-breakpoint(min-width $medium-screen 12);
$large-screen-up: new-breakpoint(min-width $large-screen 12);

$large-screen-down: new-breakpoint(max-width $large-screen 12);
$medium-screen-down: new-breakpoint(max-width $medium-screen 12);
$small-screen-down: new-breakpoint(max-width $small-screen 12);

// Typography
$sans-serif: 'Montserrat';
$serif: $georgia;
$base-font-family: $sans-serif;
$heading-font-family: $base-font-family;

// Font Sizes
$base-font-size: 1em;
$base-line-height: $base-font-size * 1.5;
$unitless-line-height: $base-line-height / ($base-line-height * 0 + 1); // Strip units from line-height: https://developer.mozilla.org/en-US/docs/Web/CSS/line-height#Prefer_unitless_numbers_for_line-height_values
$heading-line-height: $base-font-size * 1.25;
$base-border-radius: em(3);

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$highlight-color: #2ecc71;
$highlight-2: #f39c12;
$blue: #477DCA;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #DDD;
$light-red: #FBE3E4;
$light-yellow: #FFF6BF;
$light-green: #E6EFC2;

// Font Colors
$base-font-color: $dark-gray;
$base-accent-color: $highlight-color;
$action-color: $base-accent-color;

// Link Colors
$base-link-color: $base-accent-color;
$hover-link-color: darken($base-accent-color, 15);
$base-button-color: $base-link-color;
$hover-button-color: $hover-link-color;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;
