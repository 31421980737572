.partnership{
  .partnership_box{
    width: 100%;
    overflow: hidden;
    display: flex;
    padding-bottom: 40px;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    border-bottom: 2px solid #e5e5e5;
    .partnership_text{
      width: 50%;
      padding-right: 10px;
    }
  }
}
@media (max-width: 600px){
  .partnership{
    .partnership_box{
      display: block;
      text-align: center;
      padding-bottom: 20px;
      .partnership_text{
        width: 100%;
        padding: 0 3em;
      }
    }
  }
}